import { format, distanceInWords, differenceInDays } from 'date-fns'
import React from 'react'
import { Link } from 'gatsby'
import { getBlogUrl } from '../lib/helpers'
// import {buildImageObj} from '../lib/helpers'
// import {imageUrlFor} from '../lib/image-url'
import PortableText from './portableText'
import Container from './container'
// import '../styles/content.css'
import BadgeList from './BadgeList'

// import AuthorList from './author-list'

function BlogPost (props) {
  const { _rawBody, title, publishedAt, categories, next, prev } = props
  function goBack () {
    window.history.back()
  }

  return (
    <article>

      <Container>
        <button onClick={goBack} className='mt-5 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'>
          {/* <svg className='mr-3 h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
            <path fillRule='evenodd' d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z' clipRule='evenodd' />
          </svg> */}

          Geri</button>
        {/* <a href='#' className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-100 active:text-gray-700 transition ease-in-out duration-150'>
          Previous
        </a> */}

        <div className=''>
          <div className='prose lg:prose-md mt-8'>
            <h1 className=''>{title}</h1>
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>

          <aside className='mt-10'>
            {publishedAt && (
              <div className='mb-3'><span>Published: </span>
                {differenceInDays(new Date(publishedAt), new Date()) > 3
                  ? distanceInWords(new Date(publishedAt), new Date())
                  : format(new Date(publishedAt), 'MMMM Do, YYYY')}
              </div>
            )}





<div class="bg-white">
  <div class="max-w-7xl mx-auto py-0 px-0 sm:px-0 lg:py-0 lg:px-0">
    <form class="mt-8 sm:flex" action="https://tinyletter.com/gulcanyayla" method="post" target="popupwindow" onsubmit="window.open('https://tinyletter.com/gulcanyayla', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true">
      <label for="tlemail" class="sr-only">Email address</label>
        <input id="emailAddress" name="email" id="tlemail" type="email" autocomplete="email" required class="w-full px-5 py-3 placeholder-gray-500 focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs border-gray-300 rounded-md" placeholder="Enter your email" />
        <input type="hidden" value="1" name="embed" />
      <div class="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
        <button type="submit" class="w-full flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
          Notify me
        </button>
      </div>
                </form>
      <p class="mt-3 text-sm text-gray-500">
       Yazılardan haber almak için email listeme üye olabilirsiniz. 
      </p>
  </div>
</div>

            {/* <form style={{ border: "1px solid #ccc", padding: "3px", textAlign: "center" }}
              action="https://tinyletter.com/gulcanyayla" method="post" target="popupwindow" onsubmit="window.open('https://tinyletter.com/gulcanyayla', 'popupwindow', 'scrollbars=yes,width=800,height=600');return true"
            ><p><label for="tlemail">Enter your email address</label></p><p><input type="text" style={{ width: "140px" }} name="email" id="tlemail" /></p><input type="hidden" value="1" name="embed" /><input type="submit" value="Subscribe" /><p><a href="https://tinyletter.com" target="_blank">powered by TinyLetter</a></p></form> */}







            <BadgeList categories={categories} />

            <nav className='border-t border-gray-200 px-4 flex items-center justify-between sm:px-0'>
              {prev && (
                <div className='w-0 flex-1 flex'>
                  <Link to={getBlogUrl(prev.publishedAt, prev.slug.current)} className='-mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150'>
                    <svg className='mr-3 h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
                      <path fillRule='evenodd' d='M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z' clipRule='evenodd' />
                    </svg>
                    {prev.title}
                    {/* Previous */}
                  </Link>
                </div>)}
              {next && (
                <div className='w-0 flex-1 flex justify-end text-right'>
                  <Link to={getBlogUrl(next.publishedAt, next.slug.current)} className='-mt-px border-t-2 border-transparent pt-4 pr-1 inline-flex items-center text-sm leading-5 font-medium text-gray-500 hover:text-gray-700 hover:border-gray-300 focus:outline-none focus:text-gray-700 focus:border-gray-400 transition ease-in-out duration-150'>
                    {next.title}
                    <svg className='ml-3 h-5 w-5 text-gray-400' viewBox='0 0 20 20' fill='currentColor'>
                      <path fillRule='evenodd' d='M12.293 5.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-2.293-2.293a1 1 0 010-1.414z' clipRule='evenodd' />
                    </svg>
                  </Link>
                </div>
              )}

            </nav>
          </aside>
        </div>
      </Container>
    </article>
  )
}

export default BlogPost
