import React from 'react'
import { Link } from 'gatsby'
import { upperCase } from '../lib/helpers'

const Badge = ({ categories }) => {
  return (
    <>
      <Link to={`/blog/${categories.slug.current}`} className='mr-2 hover:underline inline-flex items-center px-3 py-1 rounded-full text-sm font-medium leading-5 bg-gray-200 text-gray-700'>
        {upperCase(categories.title)}
      </Link>
    </>
  )
}

export default Badge
