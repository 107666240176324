import React from 'react'
import Badge from './Badge'

const BadgeList = ({ categories }) => {
  return (
    <div className='my-5'>
      {categories.map(item => (
        <Badge categories={item} />
      ))}

    </div>
  )
}
export default BadgeList
